@import "src/styles/sass";

.phone-modal {
  padding-top: 40px;

  @include media-up(md) {
    padding-top: 0;
    width: 580px;
  }

  .ingos-modal__content {
    padding: 10px 16px 43px;
    @include media-up(md) {
      padding: 48px;
    }
  }

  &__title {
    @include h5;
    margin-bottom: 32px;

    @include media-up(md) {
      @include h4;
      margin-bottom: 56px;
    }
  }
}
