@import "src/styles/sass";

.requisites {
  &__wrapper {
    @include flex($direction: column);
    gap: 25px;
  }

  &__section {
    @include flex($direction: column);
    gap: 8px;
  }

  &__section-title {
    @include body0;
  }

  &__value {
    @include body0;
    font-weight: 300;
  }

  &__section--purpose {
    gap: 16px;
  }

  &__section-copy {
    @include flex($justify: space-between);
  }
}
