@import "src/styles/sass";

.requisite-with-copy {
  @include flex($justify: space-between);

  &__value {
    @include body0;
    font-weight: 300;
  }

  &__icon {
    cursor: pointer;
  }
}
