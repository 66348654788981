@import "styles/sass";

.personal-data {
  @include flex($direction: column);
  gap: 48px;

  &__section {
    @include flex($direction: column);
    gap: 32px;
  }

  &__subtitle {
    @include h6;
  }

  &__button-wrapper {
    @include flex($direction: column, $align: center);
  }

  &__button {
    width: 206px;
  }

  &__disclaimer {
    @include caption12;
    color: $text-secondary;
  }

  .ingos-dropzone {
    max-width: 520px;
    justify-content: start;

    @include media-up(md) {
      padding: 16px 24px;
    }
  }

  .ingos-dropzone__content {
    max-width: none;
  }
}
