@import "src/styles/sass";

.phone-form {
  @include flex($direction: column);

  &__phone-input {
    height: 88px; // 56px + 32px
    @include media-up(md) {
      height: 112px; // 56px + 48px
    }
  }

  &__text {
    margin-top: 32px;
  }

  &__error {
    margin-bottom: 16px;

    @include media-up(md) {
      margin-top: -34px;
    }
  }
}
