@import "styles/sass";

$link-secondary: #ac85ff;

.client-documents {
  @include flex($direction: column);
  gap: 16px;

  &__description {
    @include body1;
    margin: 0;
  }

  &__action {
    position: fixed;
    bottom: 32px;
    width: calc(100% - 32px);

    @include media-up(md) {
      margin-top: 32px;
      position: static;
      width: auto;
    }
  }

  &__disclaimer {
    @include caption12;
    color: $text-secondary;

    a {
      color: $link-secondary;
    }
  }
}
