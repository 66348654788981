@import "src/styles/sass";

.auth-logo {
  padding: 32px 40px 40px 114px;
  border-radius: 0 16px 16px 0;

  position: absolute;
  top: 156px;
  left: -2px;

  &::before {
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    left: 0;
    height: 100%;
    padding: 1px;
    border-radius: 8px;
    content: "";
    mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
    pointer-events: none;
  }

  img {
    width: 344px;
    height: 84px;
  }
}
