@import "src/styles/sass";

.exit-modal {
  padding: 40px;

  &__header {
    margin-bottom: 16px;
    @include h6;
  }

  &__text {
    margin-bottom: 32px;
    @include body1;
  }

  &__footer {
    @include flex($direction: column);

    .ingos-btn:not(:last-of-type) {
      margin-bottom: 12px;
    }
  }
}

@include media-up(md) {
  .exit-modal {
    width: 560px;
    box-sizing: content-box;
    padding: 48px;

    &__header {
      margin-bottom: 24px;
    }

    &__text {
      margin-bottom: 48px;
    }

    &__footer {
      flex-direction: row;

      .ingos-btn {
        flex: 1;
        margin-bottom: 0;
      }
    }

    &__exit-btn {
      order: -1;
      margin-right: 12px;
    }
  }
}
