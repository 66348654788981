.checkbox-input {
  margin-bottom: 18px;

  .ingos-checkbox-item {
    &__label {
      align-items: flex-start;
    }

    &__control {
      transform: translateY(4px);
    }
  }
}
