$grid-breakpoints: (
  xs: 0,
  xs1: 320,
  sm: 360,
  sm1: 450,
  sm2: 550,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1440,
);

$text-primary: var(--text-primary);
$text-secondary: var(--text-secondary);
$text-disable: var(--text-disable);
$text-white: var(--text-white);
$text-black: var(--text-black);
$text-accent-red: var(--text-accent-red);
$text-accent-blue: var(--text-accent-blue);
$text-success-green: var(--text-success-green);
$text-error: var(--text-error);
$bg-background-black: var(--bg-background-black);
$bg-black: var(--bg-black);
$bg-background-gray: var(--bg-background-gray);
$bg-white: var(--bg-white);
$bg-darkgray: var(--bg-darkgray);
$btn-accent-red: var(--btn-accent-red);
$btn-red-hover: var(--btn-red-hover);
$btn-red-press: var(--btn-red-press);
$btn-disable: var(--btn-disable);
$btn-white: var(--btn-white);
$btn-almost-white: var(--btn-almost-white);
$btn-white-hover: var(--btn-white-hover);
$btn-white-press: var(--btn-white-press);
$element-accent-red: var(--element-accent-red);
$element-accent-blue: var(--element-accent-blue);
$element-black: var(--element-black);
$element-white: var(--element-white);
$element-almost-white: var(--element-almost-white);
$element-gray: var(--element-gray);
$element-lightgray: var(--element-lightgray);
$element-green: var(--element-green);
$element-red60: var(--element-red60);
$element-red40: var(--element-red40);
$element-red10: var(--element-red10);
$element-blue60: var(--element-blue60);
$element-blue40: var(--element-blue40);
$element-blue10: var(--element-blue10);
$secondary-nicaragua: var(--secondary-nicaragua);
$secondary-dominica: var(--secondary-dominica);
$secondary-kiribati: var(--secondary-kiribati);
$secondary-argentina: var(--secondary-argentina);
$secondary-palau: var(--secondary-palau);
$secondary-sri-lanka: var(--secondary-sri-lanka);
$secondary-ireland: var(--secondary-ireland);
$secondary-colombia: var(--secondary-colombia);
$secondary-armenia: var(--secondary-armenia);
$secondary-nicaragua50: var(--secondary-nicaragua50);
$secondary-nicaragua25: var(--secondary-nicaragua25);
$secondary-dominica50: var(--secondary-dominica50);
$secondary-dominica25: var(--secondary-dominica25);
$secondary-kiribati50: var(--secondary-kiribati50);
$secondary-kiribati25: var(--secondary-kiribati25);
$secondary-argentina50: var(--secondary-argentina50);
$secondary-argentina25: var(--secondary-argentina25);
$secondary-palau50: var(--secondary-palau50);
$secondary-palau25: var(--secondary-palau25);
$secondary-sri-lanka50: var(--secondary-sri-lanka50);
$secondary-sri-lanka25: var(--secondary-sri-lanka25);
$secondary-ireland50: var(--secondary-ireland50);
$secondary-ireland25: var(--secondary-ireland25);
$secondary-colombia50: var(--secondary-colombia50);
$secondary-colombia25: var(--secondary-colombia25);
$secondary-armenia50: var(--secondary-armenia50);
$secondary-armenia25: var(--secondary-armenia25);

$input-focus-border: 1px solid $element-blue60;
$input-border: 1px solid $bg-background-gray;
$table-border: 1px solid $element-lightgray;
$transition: 0.3s ease;

// NEW PALETTE ----------------------------
// adaptive
$palette-primary: var(--palette-primary);
$palette-secondary: var(--palette-secondary);
$palette-soft: var(--palette-soft);
$palette-accent: var(--palette-accent);
$palette-accent-pressed: var(--palette-accent-pressed);
$palette-disable: var(--palette-disable);
$palette-inverted: var(--palette-inverted);
$palette-bg-gray-1: var(--palette-bg-gray-1);
$palette-bg-gray-2: var(--palette-bg-gray-2);
$palette-success: var(--palette-success);
$palette-error: var(--palette-error);
$palette-icon-gradient: var(--palette-icon-gradient);

// buttons
$palette-btn: var(--palette-btn);
$palette-btn-hover: var(--palette-btn-hover);
$palette-btn-press: var(--palette-btn-press);
$palette-btn-secondary: (--palette-btn-secondary);
$palette-btn-secondary-hover: var(--palette-btn-secondary-hover);
$palette-btn-secondary-press: var(--palette-btn-secondary-press);

// constant
$palette-constant-footer-black: var(--palette-constant-footer-black);
$palette-constant-green: var(--palette-constant-green);
$palette-constant-almoust-white: var(--palette-constant-almoust-white);
$palette-constant-black: var(--palette-constant-black);
$palette-constant-red-60: (--palette-constant-red-60);
$palette-constant-white: var(--palette-constant-white);
$palette-constant-red-40: (--palette-constant-red-40);
$palette-constant-red-10: (--palette-constant-red-10);
$palette-constant-blue-60: (--palette-constant-blue-60);
$palette-constant-red: var(--palette-constant-red);
$palette-constant-blue-40: (--palette-constant-blue-40);
$palette-constant-blue-10: (--palette-constant-blue-10);
$palette-constant-purple-medium: var(--palette-constant-purple-medium);
$palette-constant-purple-light: var(--palette-constant-purple-light);
$palette-constant-purple-extra-light: var(
  --palette-constant-purple-extra-light
);

// secondary
$palette-secondary-nicaragua: var(--palette-secondary-nicaragua);
$palette-secondary-dominica: var(--palette-secondary-dominica);
$palette-secondary-kiribati: var(--palette-secondary-kiribati);
$palette-secondary-argentina: var(--palette-secondary-argentina);
$palette-secondary-palau: var(--palette-secondary-palau);
$palette-secondary-sri-lanka: var(--palette-secondary-sri-lanka);
$palette-secondary-ireland: var(--palette-secondary-ireland);
$palette-secondary-colombia: var(--palette-secondary-colombia);
$palette-secondary-armenia: var(--palette-secondary-armenia);
$palette-secondary-nicaragua-50: var(--palette-secondary-nicaragua-50);
$palette-secondary-nicaragua-25: var(--palette-secondary-nicaragua-25);
$palette-secondary-dominica-50: var(--palette-secondary-dominica-50);
$palette-secondary-dominica-25: var(--palette-secondary-dominica-25);
$palette-secondary-kiribati-50: var(--palette-secondary-kiribati-50);
$palette-secondary-kiribati-25: var(--palette-secondary-kiribati-25);
$palette-secondary-argentina-50: var(--palette-secondary-argentina-50);
$palette-secondary-argentina-25: var(--palette-secondary-argentina-25);
$palette-secondary-palau-50: var(--palette-secondary-palau-50);
$palette-secondary-palau-25: var(--palette-secondary-palau-25);
$palette-secondary-sri-lanka-50: var(--palette-secondary-sri-lanka-50);
$palette-secondary-sri-lanka-25: var(--palette-secondary-sri-lanka-25);
$palette-secondary-ireland-50: var(--palette-secondary-ireland-50);
$palette-secondary-ireland-25: var(--palette-secondary-ireland-25);
$palette-secondary-colombia-50: var(--palette-secondary-colombia-50);
$palette-secondary-colombia-25: var(--palette-secondary-colombia-25);
$palette-secondary-armenia-50: var(--palette-secondary-armenia-50);
$palette-secondary-armenia-25: var(--palette-secondary-armenia-25);
$palette-secondary-purple-start: var(--palette-secondary-purple-start);
$palette-secondary-purple-end: var(--palette-secondary-purple-end);
$palette-secondary-purple-pressed-1: var(--palette-secondary-purple-pressed-1);
$palette-secondary-purple-pressed-2: var(--palette-secondary-purple-pressed-2);

// gradient
$palette-gradient-accent: var(--palette-gradient-accent);
$palette-gradient-light: var(--palette-gradient-light);
$palette-gradient-white: var(--palette-gradient-white);
$palette-gradient-ingos: var(--palette-gradient-ingos);
$palette-gradient-red-ingos: var(--palette-gradient-red-ingos);
$palette-gradient-emerald: var(--palette-gradient-emerald);
$palette-gradient-kiribati: var(--palette-gradient-kiribati);
$palette-gradient-argentina: var(--palette-gradient-argentina);
$palette-gradient-palau: var(--palette-gradient-palau);
$palette-gradient-sri-lanka: var(--palette-gradient-sri-lanka);
$palette-gradient-ireland: var(--palette-gradient-ireland);
$palette-gradient-colombia: var(--palette-gradient-colombia);
$palette-gradient-armenia: var(--palette-gradient-armenia);
$palette-gradient-nicaragua: var(--palette-gradient-nicaragua);
$palette-gradient-dominica: var(--palette-gradient-dominica);

// END NEW PALETTE -------------------------
