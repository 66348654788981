@import "src/styles/sass";

$height: 56px;

.select-control {
  height: $height;
  width: 100%;
  border: 1px solid $element-lightgray;
  border-radius: 8px;
  cursor: pointer;
  padding: 16px;
  position: relative;

  @include flex($align: center);

  &__placeholder {
    @include body1;
    color: $text-secondary;
  }

  &__chevron {
    margin-left: auto;
    @include svg-color(#333);
    @include svg-size(20px, 20px);
    transition: $transition;
  }

  &__value {
    @include body1;
    color: $text-primary;
  }

  &__label {
    @include caption12;
    color: $text-secondary;
  }

  &__content {
    @include flex($direction: column, $justify: space-between);
  }

  &--open {
    .select-control__chevron {
      transform: rotate(180deg);
    }
  }

  &--has-value {
    padding: 8px 16px;
  }
}
