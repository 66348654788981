@import "src/styles/sass";

$note-bg: $palette-constant-purple-medium;
$note-text-color: $palette-constant-white;

.badge {
  background: $note-bg;
  color: $note-text-color;

  @include flex-centered;
  @include mini-caption10;

  padding: 2px 8px;
  border-radius: 16px;
}
