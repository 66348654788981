@import "src/styles/mixins";
@import "src/styles/variables";
@import "src/styles/fonts";

.autocomplete {
  $this: &;
  position: relative;

  &__input {
    width: 100%;
  }

  &__popup {
    @include shadow;
    position: absolute;
    z-index: 2;
    left: 0;
    bottom: 0;
    max-width: 100%;
    max-height: 416px;
    width: 100%;
    transform: translateY(calc(100% + 12px));
    border-radius: 8px;
    background-color: #fff;
    padding: 8px 12px;
    -ms-overflow-style: none;
    scrollbar-width: none;
    overflow: scroll;

    &::-webkit-scrollbar {
      width: 0;
      height: 0;
    }
  }

  &__suggestion {
    @include body1;
    cursor: pointer;
    padding: 8px 16px;
    border-radius: 8px;
    transition: all $transition;

    &:hover {
      background-color: $secondary-argentina25;
    }
  }
}
