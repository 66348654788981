@import "src/styles/sass";

$error-color: $text-error;

.error-message {
  $self: &;

  display: inline-flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;

  margin-top: 4px;

  &__text {
    @include caption12;
    color: $error-color;
  }

  &__icon {
    @include svg-size(12px, 12px);
    @include svg-color($color: $error-color);

    margin-right: 6px;

    svg {
      vertical-align: top;
    }
  }
}
