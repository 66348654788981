@import "src/styles/sass";

.qr-code {
  background: $bg-background-gray;
  padding: 24px 16px;
  border-radius: 16px;

  &__note {
    @include body1;
    text-align: center;
  }

  &__code {
    margin-top: 36px;
    @include flex($justify: center);
  }

  &--opened {
    .qr-code__title {
      color: $palette-accent;
    }
  }
}
