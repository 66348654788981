@import "styles/fonts";
@import "styles/mixins";

.result-card {
  background: linear-gradient(135deg, #ecf6ff 0%, #f1ecff 100%);
  padding: 32px;
  border-radius: 16px;
  text-align: center;

  &__title {
    @include h5;
  }

  &__text {
    @include body1;
    @include flex($direction: column);
    row-gap: 24px;

    p {
      margin: 0;
    }
  }

  &--payment {
    position: relative;
    padding: 72px 32px 32px;

    .result-card__icon {
      position: absolute;
      top: -72px;
      right: calc(50% - 96px);
    }
  }
}
