@import "src/styles/sass";

.steps {
  display: flex;
  flex-flow: row nowrap;
  align-items: start;
  justify-content: start;
  padding: 0 0 7px;
  margin: 0;
  list-style: none;
  overflow-x: auto;

  &__item {
    flex-shrink: 0;
  }

  &__item:not(:last-child) {
    margin-right: 32px;
  }
}

.step-item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: start;
  padding-bottom: 8px;

  &__text {
    color: $palette-primary;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 20px;
  }

  &__icon {
    width: 20px;
    height: 20px;
    margin-right: 12px;
  }

  &--active {
    border-bottom: 2px solid $palette-secondary-purple-pressed-2;
    @include svg-color($color: $palette-secondary-purple-pressed-2);

    .step-item__text {
      font-weight: 500;
    }
  }

  &--passed {
    @include svg-color($color: $palette-secondary-purple-pressed-2);
  }

  &--default {
    @include svg-color($color: $palette-disable);

    .step-item__text {
      color: $palette-secondary;
    }
  }
}

@include media-up(md) {
  .steps {
    flex-direction: column;
    padding: 0;
    overflow-x: initial;

    &__item:not(:last-child) {
      margin-right: 0;
      margin-bottom: 24px;
    }
  }

  .step-item {
    padding-bottom: 0;

    &--active {
      border: 0;
    }
  }
}
