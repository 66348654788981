@import "src/styles/variables";
@import "src/styles/fonts";

@mixin flex(
  $justify: flex-start,
  $align: stretch,
  $direction: row,
  $wrap: nowrap
) {
  display: flex;
  flex-flow: $direction $wrap;
  align-items: $align;
  justify-content: $justify;
}

@mixin flex-centered(
  $justify: center,
  $align: center,
  $direction: row,
  $wrap: nowrap
) {
  display: flex;
  flex-flow: $direction $wrap;
  align-items: $align;
  justify-content: $justify;
}

@mixin hidden-scroll() {
  &::-webkit-scrollbar {
    display: none;
  }

  overflow: auto;
  scrollbar-width: none;
}

@mixin outline($width: 2px) {
  outline: $width solid $bg-background-black;
  outline-offset: -$width;
}

@mixin visually-hidden() {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

/* Применяется к обертке, svg читает цвет из currentColor */
@mixin svg-color($color: currentColor) {
  path,
  circle {
    fill: $color;
  }

  path[stroke],
  circle[stroke] {
    fill: none;
    stroke: $color;
  }
}

/* Применяется к обертке, svg скейлится */
@mixin svg-size($width: 20px, $height: $width) {
  width: $width;
  height: $height;

  & svg {
    width: 100%;
    height: 100%;
  }
}

// xs sm md lg xl xxl
@mixin media-up($breakpoint) {
  @media (min-width: #{map-get($grid-breakpoints, $breakpoint)}px) {
    @content;
  }
}

@mixin media-down($breakpoint) {
  @media (max-width: #{map-get($grid-breakpoints, $breakpoint) - 1}px) {
    @content;
  }
}

@mixin media-between($from, $to) {
  @media (min-width: #{map-get($grid-breakpoints, $from)}px) and (max-width: #{map-get($grid-breakpoints, $to) - 1}px) {
    @content;
  }
}

@mixin text-ellipsis() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

@mixin shadow {
  /* stylelint-disable-next-line plugin/no-restricted-syntax */
  box-shadow: 0 4px 14px rgba(0, 66, 138, 0.1);
}

@mixin table() {
  @include base-typography;

  font-size: 14px;
  /* stylelint-disable-next-line plugin/no-restricted-syntax */
  line-height: 20px;
}

// работает только со светлым фоном
@mixin inner-text-shadow($color) {
  background-color: $color;
  color: transparent;
  text-shadow: 2px 2px 3px rgba(255, 255, 255, 0.3);
  background-clip: text;
}

@mixin line-clamp($lines) {
  /* stylelint-disable-next-line value-no-vendor-prefix */
  display: -webkit-box;
  overflow: hidden;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $lines;
  line-clamp: $lines;
  text-overflow: ellipsis;
}

@mixin table-scroll() {
  scrollbar-color: $element-blue60 $element-lightgray;
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    height: 4px;
  }

  &::-webkit-scrollbar-track {
    background-color: $element-lightgray;
    border-radius: 3px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: $element-blue60;
    border-radius: 3px;
  }
}

@mixin number-input {
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
}

@mixin paint-link(
  $mainColor: $text-white,
  $hoverColor: $text-accent-red,
  $withSvg: false
) {
  transition: color $transition;

  &,
  &:visited {
    color: $mainColor;
  }

  @if ($withSvg) {
    @include svg-color($mainColor);
  }

  &:hover,
  &:active {
    color: $hoverColor;
    @if ($withSvg) {
      @include svg-color($hoverColor);
    }
  }
}

@mixin footer-margin() {
  margin-bottom: 64px;

  @include media-up(md) {
    margin-bottom: 150px;
  }
}

@mixin footer-margin-with-disclaimer() {
  margin-bottom: 48px;
}

@mixin gradient-text($gradient) {
  background: $gradient;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

@mixin revert-gradient-text($color) {
  background: inherit;
  background-clip: inherit;
  -webkit-text-fill-color: inherit;
  color: $color;
}

@mixin gradient-border($border-radius: 8px, $color: var(--palette-btn)) {
  position: relative;

  &::before {
    position: absolute;
    top: -1px;
    right: -1px;
    bottom: -1px;
    left: -1px;
    border: 1px solid transparent;
    background: $color border-box;
    border-radius: $border-radius;
    content: "";
    mask: linear-gradient(#fff 0 0) padding-box, linear-gradient(#fff 0 0);
    mask-composite: exclude;
  }
}
