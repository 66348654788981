@import "src/styles/sass";

.documents {
  &__doc-group-name {
    @include subtitle1;

    margin-top: 40px;
    margin-bottom: 32px;
  }

  &__doc:not(:last-of-type) {
    margin-bottom: 16px;
  }

  &__buttons-wrapper {
    margin-top: 72px;
    @include flex($direction: column, $align: center);
    gap: 12px;

    button {
      width: 260px;
    }

    .ingos-btn__left-content {
      @include svg-color($text-success-green);
    }
  }

  &__note {
    @include flex($direction: column, $align: center);
    gap: 24px;

    border-radius: 16px;
    background-color: $palette-bg-gray-2;
    padding: 32px;
  }

  &__note-text {
    @include body0;
    text-align: center;
  }
}
