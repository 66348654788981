@import "styles/variables";
@import "styles/fonts";

.sbp-payment {
  $this: &;
  background: $bg-background-gray;
  padding: 32px 16px;
  border-radius: 16px;
  text-align: center;

  &__title,
  &__subtitle {
    margin: 0;
  }

  &__action {
    margin-top: 24px;
    width: 260px;
  }

  &__request {
    #{$this} {
      &__title {
        @include subtitle1;
        margin: 0;
      }

      &__subtitle {
        @include body1;
      }
    }
  }

  &__response {
    #{$this} {
      &__title {
        @include body0;
      }

      &__subtitle {
        margin-top: 24px;
        @include body1-xs;
      }
    }
  }

  &__error-informer {
    text-align: start;
    margin-top: 24px;
  }
}
