@import "styles/sass";

.product-form {
  width: 580px;
  padding: 40px 0;

  &__header {
    @include h5;
    padding: 16px;

    @include media-up(md) {
      @include h4;
      padding: 16px 48px;
    }
  }

  &__body {
    padding: 16px;
    display: grid;
    row-gap: 16px;

    @include media-up(md) {
      padding: 16px 48px;
    }
  }
}
