@import "styles/variables";
@import "styles/fonts";

.documents-success {
  &__content {
    display: grid;
    row-gap: 48px;
  }

  &__summary {
    @include body1;
    text-align: center;
  }

  &__email {
    color: $text-accent-red;
  }

  &__credentials {
    margin-top: 16px;
  }
}
