@import "src/styles/variables";

@mixin base-typography() {
  font-family: IngoStem, sans-serif;
  font-style: normal;
}

@mixin typography-enhancer($weight, $style) {
  @if $weight {
    font-weight: $weight;
  }
  @if $style {
    font-style: $style;
  }
}

@mixin h0($weight: null, $style: null) {
  @include base-typography;

  font-size: 64px;
  font-weight: bold;
  line-height: 72px;
  @include typography-enhancer($weight, $style);
}

@mixin h1($weight: null, $style: null) {
  @include base-typography;

  font-size: 48px;
  font-weight: bold;
  line-height: 56px;
  @include typography-enhancer($weight, $style);
}

@mixin h2($weight: null, $style: null) {
  @include base-typography;

  font-size: 40px;
  font-weight: bold;
  line-height: 48px;
  @include typography-enhancer($weight, $style);
}

@mixin h3($weight: null, $style: null) {
  @include base-typography;

  font-size: 32px;
  font-weight: bold;
  line-height: 40px;
  @include typography-enhancer($weight, $style);
}

@mixin h4($weight: null, $style: null) {
  @include base-typography;

  font-size: 28px;
  font-weight: bold;
  line-height: 36px;
  @include typography-enhancer($weight, $style);
}

@mixin h5($weight: null, $style: null) {
  @include base-typography;

  font-size: 24px;
  font-weight: bold;
  line-height: 32px;
  @include typography-enhancer($weight, $style);
}

@mixin h6($weight: null, $style: null) {
  @include base-typography;

  font-size: 20px;
  font-weight: bold;
  line-height: 24px;
  @include typography-enhancer($weight, $style);
}

@mixin h7($weight: null, $style: null) {
  @include base-typography;

  font-size: 18px;
  font-weight: bold;
  line-height: 26px;
  @include typography-enhancer($weight, $style);
}

@mixin subtitle1($weight: null, $style: null) {
  @include base-typography;

  font-size: 18px;
  font-weight: 500;
  line-height: 26px;
  @include typography-enhancer($weight, $style);
}

@mixin subtitle2($weight: null, $style: null) {
  @include base-typography;

  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  @include typography-enhancer($weight, $style);
}

@mixin subtitle3($weight: null, $style: null) {
  @include base-typography;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  @include typography-enhancer($weight, $style);
}

@mixin tabs2($weight: null, $style: null) {
  @include base-typography;

  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  @include typography-enhancer($weight, $style);
}

@mixin body0($weight: null, $style: null) {
  @include base-typography;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  @include typography-enhancer($weight, $style);
}

@mixin body1($weight: null, $style: null) {
  @include base-typography;

  font-size: 16px;
  font-weight: normal;
  line-height: 24px;
  @include typography-enhancer($weight, $style);
}

@mixin body1-xs($weight: null, $style: null) {
  @include base-typography;

  font-size: 16px;
  font-weight: normal;
  line-height: 20px;
  @include typography-enhancer($weight, $style);
}

@mixin body2($weight: null, $style: null) {
  @include base-typography;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  @include typography-enhancer($weight, $style);
}

@mixin body3($weight: null, $style: null) {
  @include base-typography;

  font-size: 14px;
  font-weight: normal;
  line-height: 20px;
  @include typography-enhancer($weight, $style);
}

@mixin button1($weight: null, $style: null) {
  @include base-typography;

  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
  @include typography-enhancer($weight, $style);
}

@mixin button2($weight: null, $style: null) {
  @include base-typography;

  font-size: 14px;
  font-weight: 500;
  line-height: 20px;
  @include typography-enhancer($weight, $style);
}

@mixin caption12($weight: null, $style: null) {
  @include base-typography;

  font-size: 12px;
  font-weight: normal;
  line-height: 16px;
  @include typography-enhancer($weight, $style);
}

@mixin mini-caption10($weight: null, $style: null) {
  @include base-typography;

  font-size: 10px;
  font-weight: normal;
  line-height: 12px;
  @include typography-enhancer($weight, $style);
}
