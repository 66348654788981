@import "src/styles/variables";
@import "src/styles/fonts";

.error-modal {
  width: 100%;
  max-width: 664px;
  padding: 48px;

  &__body {
    display: grid;
    row-gap: 32px;
  }

  &__icon {
    display: flex;
    justify-content: center;
  }

  &__title {
    @include h5;
    margin: 0;
    text-align: center;
  }

  &__content {
    display: grid;
    row-gap: 16px;
  }

  &__subtitle {
    @include body0(600);
    margin: 0;
    color: $text-accent-red;
  }

  &__error-text {
    @include body0(400);
    margin: 0;
  }

  &__action {
    margin-top: 16px;
    display: flex;
    justify-content: center;
  }
}
