@import "src/styles/sass";

.payment-ways {
  margin-top: 72px;

  &__title {
    @include h3;
  }

  &__content {
    margin-top: 32px;
    @include flex($direction: column);
    gap: 24px;
  }

  &__way {
    @include flex($justify: space-between);
    transition: $transition;
    cursor: pointer;
  }

  &__way-chevron {
    transition: $transition;
  }

  &__way--opened {
    color: $palette-accent;

    .payment-ways__way-chevron {
      @include svg-color($palette-secondary-purple-pressed-2);

      transform: rotate(90deg);
    }
  }

  &__way-name {
    @include subtitle1;
    @include flex($align: center);
    column-gap: 12px;
  }

  &__requisites-wrapper {
    @include flex($direction: column);
    gap: 25px;
  }

  &__requisites-section {
    @include flex($direction: column);
    gap: 8px;
  }

  &__requisites-section-title {
    @include body0;
  }

  &__requisite-value {
    @include body0;
    font-weight: 300;
  }

  &__requisites-section--purpose {
    gap: 16px;
  }

  &__requisites-section-copy {
    @include flex($justify: space-between);
  }
}
