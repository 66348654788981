@import "src/styles/sass";

.payment {
  .page-wrapper__title {
    margin-bottom: 8px;
  }

  &__fio {
    @include subtitle1;
    color: $text-secondary;
  }

  &__order-info {
    margin-top: 32px;
  }

  &__fund {
    @include subtitle1;
  }

  &__order {
    margin-top: 8px;
    @include body1;
    color: $text-secondary;
  }

  &__order-separator {
    border: none;
    color: $element-lightgray;
    background-color: $element-lightgray;
    height: 1px;

    margin: 24px 0;
  }

  &__summary {
    @include h5;
    display: grid;
    grid-template-columns: 1fr auto;
    column-gap: 24px;
    border-radius: 16px;
    background: linear-gradient(135deg, #ecf6ff 0%, #f1ecff 100%);
    padding: 24px;
  }
}
