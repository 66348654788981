@import "src/styles/sass";

.auth {
  @include media-up(md) {
    background-image: url("/assets/login-background.png");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: right bottom;
    background-attachment: fixed;
    background-color: $bg-background-gray;
    width: 100vw;
    height: 100vh;
    position: relative;
  }
}

.ingos-backdrop {
  backdrop-filter: blur(6px);
}
