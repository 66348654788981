@import "styles/variables";
@import "styles/fonts";

.payment-success {
  &__content {
    display: flex;
    flex-direction: column;
    row-gap: 8px;
  }
}
