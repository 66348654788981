@import "src/styles/sass";

.page-wrapper {
  &__head {
    @include flex($justify: flex-end, $align: center);
    margin-top: 48px;
  }

  &__exit {
    @include svg-color($palette-primary);
    @include svg-size(20px, 20px);
    cursor: pointer;

    margin-right: 8vw;

    &:hover {
      @include svg-color($palette-secondary-purple-pressed-2);
    }
  }

  &__content {
    padding: 16px;
    max-width: 520px;
    margin: 0 auto;
  }

  &__title {
    @include h5;
    margin-bottom: 40px;

    @include media-up(md) {
      @include h3;
      margin-bottom: 48px;
      width: 448px;
    }
  }
}
