@import "src/styles/sass";

.real-sign-modal {
  padding: 16px 8px;

  @include media-up(md) {
    padding: 48px;
  }

  @include flex($direction: column, $align: center);
  gap: 24px;

  &__title {
    @include h5;
  }

  &__documents {
    width: 100%;
    min-height: 132px;

    .ingos-dropzone {
      width: 100%;
      max-width: none;
    }
  }

  &__button {
    width: 260px;
    margin-top: 4px;
  }
}
