@import "src/styles/sass";

.client-documents-error-modal {
  padding: 32px;

  @include media-up(md) {
    padding: 32px 48px 48px;
    min-width: 648px;
  }

  &__title {
    @include h5;
  }

  &__text {
    @include body1;

    margin-top: 32px;
  }

  &__button-wrapper {
    @include flex($justify: center);

    margin-top: 32px;
  }

  &__button {
    width: 100%;

    @include media-up(md) {
      width: 260px;
    }
  }
}
