@import "src/styles/sass";

.payment-button-section {
  margin-top: 16px;

  &__note {
    padding: 24px 16px;
    background: $bg-background-gray;
    border-radius: 16px;

    text-align: center;

    @include body3;
  }

  &__buttons {
    margin-top: 24px;
    @include flex($direction: column);
    gap: 12px;

    @include media-up(md) {
      @include flex($justify: space-between);
    }
  }

  &__exit {
    @include media-up(md) {
      width: 136px;
    }
  }

  &__register-new {
    @include media-up(md) {
      width: 352px;
    }
  }
}
