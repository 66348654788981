@import "src/styles/sass";

.fund-select {
  @include flex($direction: column);
  gap: 16px;

  .ingos-input,
  .select {
    height: 74px;
  }

  .fund-select__estimate {
    height: 94px;
  }

  &__button-wrapper {
    @include flex($justify: center);
    width: 100%;
    margin-top: 12px;
  }

  &__button {
    width: 260px;
  }
}
