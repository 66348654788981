@import "styles/sass";

.client-documents-success {
  &__button-wrapper {
    position: fixed;
    bottom: 32px;
    width: 100%;

    @include media-up(md) {
      margin-top: 32px;
      position: relative;
      bottom: auto;
    }
  }

  &__button {
    width: calc(100% - 32px);

    @include media-up(md) {
      width: 100%;
    }
  }
}
