@import "styles/sass";

.layout {
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: stretch;
  justify-content: flex-start;

  &__header {
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;

    @include media-up(md) {
      box-shadow: 0 4px 14px rgb(0 66 138 / 10%);
    }
  }
}

.page {
  max-width: 520px;
  padding: 16px;
  margin: 0 auto;
}

@include media-up(md) {
  $width-sidebar: 272px;

  .layout {
    flex-flow: row nowrap;
    align-items: start;
    padding: 16px;

    &__header {
      position: fixed;
      max-width: $width-sidebar;
      margin: 0;
      border-radius: 16px;
      box-shadow: none;
    }

    &__content {
      flex: 1;
      margin-left: $width-sidebar;
    }
  }
}
