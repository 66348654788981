@import "styles/mixins";

.payment-error {
  &__button-section {
    @include flex($direction: column);
    margin-top: 48px;
    gap: 12px;

    @include media-up(md) {
      @include flex($justify: space-between);
    }
  }

  &__button {
    flex: 1;
  }
}
