@import "src/styles/sass";

.header {
  @include flex($justify: space-between, $wrap: wrap);
  padding: 16px 16px 5px;
  background-color: #fff;

  &__steps {
    width: 100%;
    order: 1;
    margin-top: 19px;
    @include hidden-scroll;
  }
}

@include media-up(md) {
  .header {
    height: calc(100% - 16px * 2);
    flex-direction: column;
    padding: 32px 24px;
    background-color: #f5f8fa;

    &__steps {
      order: 0;
      margin-top: 0;
    }
  }
}
